import React, { FC, useEffect, useState } from "react";
import { ConfirmDataProps } from "./types";
import styles from "../../Checkout.module.scss";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import OrderItems from "../OrderItems";
import { TCartItem } from "../../../../../store/cart/types";
import { useAppDispatch, useAppSelector } from "../../../../../store/hook";
import { removeFromCart } from "../../../../../store/cart/slice";
import $api from "../../../../../http";
import { IOrderItem, IOrderRequest } from "../../../../../store/orders/types";
import { setOrderRequest } from "../../../../../store/orders/thunks";
import EOrderStatus from "../../../../../utils/EOrderStatus";
import NotificatorModal from "../NotificatorModal";

const ConfirmData: FC<ConfirmDataProps> = ({ cb, values }) => {
    const dispatch = useAppDispatch();
    const { userSlice: { user, isAuth }, cartSlice: { cart } } = useAppSelector(state => state);

    const { t } = useTranslation();
    const [note, setNote] = useState("");
    const [modal, setModal] = useState(false);
    const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const closeModalHandler = async (redirect: "orders" | "finances" | "") => {
        setModal(false);
        navigate(`/${redirect}`);
    };

    const orderHandler = async () => {
        if (isLoading) return;
        setIsLoading(true);
        if (values) {
            const order: IOrderRequest = {
                shipmentData: {
                    client: `${values.lastName} ${values.firstName}${values.middleName ? ` ${values.middleName}` : ""}`,
                    phone: values.phone,
                    email: values.email,
                    region: values.region?.label!,
                    city: values.city?.label!,
                    deliveryType: values.deliveryType!,
                    paymentType: values.paymentType,
                    address: values.deliveryType === "np_warehouse"
                        ? values.address?.label || ""
                        : `${values.street}, ${values.house}${values.flat
                            ? `, кв. ${values.flat}`
                            : ""}`,
                    userId: isAuth ? user.id : 0,
                    note,
                },
                items: orderItems,
            };
            dispatch(setOrderRequest(order));
            setModal(true);

            const cartItemsIds = orderItems.map(item => item.id);
            dispatch(removeFromCart(cartItemsIds));
            if (isAuth) {
                // syncUserData();
                if (orderItems.length !== cart.length)
                    await $api.delete("/api/cart/-1", { data: { ids: cartItemsIds } });
                else
                    await $api.delete("/api/cart/0");
            } else {
                if (orderItems.length !== cart.length) {
                    const cartLocal = JSON.parse(localStorage.getItem("cart")!) as TCartItem[] | undefined;
                    const newCart = cartLocal?.filter(item => !cartItemsIds.includes(item.id));
                    localStorage.setItem("cart", JSON.stringify(newCart));
                } else
                    localStorage.removeItem("cart");
            }

            window.history.replaceState({}, document.title);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const items = location.state?.items as TCartItem[] | undefined;

        if (!items) {
            navigate("/cart");
            window.history.replaceState({}, document.title);
        } else {
            const orders: IOrderItem[] = items.map(item => ({
                id: item.id,
                code: item.code,
                description: item.description,
                note: item.note,
                currency: item.currency,
                deliveryDate: item.deliveryDate,
                deliveryTypeId: item.deliveryTypeId,
                storage: item.storage,
                weight: item.weight,
                price: item.newPrice ? item.newPrice : item.price,
                brand: item.brand,
                newPrice: item.newPrice,
                group: item.group,
                multiplicity: item.multiplicity,
                productId: item.productId,
                priceLogo: item.priceLogo,
                quantityMax: item.quantityMax,
                quantityType: item.quantityType,
                states: [{ quantity: item.quantity, status: EOrderStatus.NEW, index: 0 }],
            }));
            setOrderItems(orders);
        }
//eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={styles.tabPanelContent}>
                <div className={styles.confirmBlock}>
                    <span>{t("finances.payment_details.recipient")}:</span>
                    <span>{`${values?.lastName} ${values?.firstName} ${values?.middleName ? values?.middleName : ""}`}</span>
                </div>
                <div className={styles.confirmBlock}>
                    <span>{t("form.phone")}:</span>
                    <span>{values?.phone}</span>
                </div>
                <div className={styles.confirmBlock}>
                    <span>{t("checkout.shipment.title")}:</span>
                    <span>{values?.deliveryType === "np_warehouse"
                        ? t("checkout.shipment.method.np_warehouse")
                        : t("checkout.shipment.method.np_courier")}</span>
                </div>
                <div className={styles.confirmBlock}>
                    <span>{t("contacts.address.title")}:</span>
                    <span>{values?.deliveryType === "np_warehouse"
                        ? values?.address?.label
                        : `${values?.street}, ${values?.house}`}</span>
                </div>
                {/*<div className={styles.confirmBlock}>*/}
                {/*    <span>{t('checkout.payment.title')}:</span>*/}
                {/*    <span>{values?.paymentType === 'cash'*/}
                {/*        ? t('checkout.payment.cash')*/}
                {/*        : t('checkout.payment.prepayment')}</span>*/}
                {/*</div>*/}
                <div className={cn(styles.formBlock, styles.w100)}>
                    <label htmlFor="note">{t("checkout.verify_order.note.label")}</label>
                    <textarea className={cn(styles.input, styles.textarea)} id="note" maxLength={255} value={note}
                              placeholder={t("checkout.verify_order.note.placeholder") || ""}
                              onChange={(e) => setNote(e.target.value)}/>
                </div>
            </div>
            <div className={styles.btnWrapper}>
                <button className="btn cancel" onClick={() => cb(1)}>{t("checkout.previous")}</button>
                <button className="btn" disabled={isLoading} onClick={orderHandler}>{t("checkout.confirm")}</button>
            </div>
            <OrderItems items={orderItems}/>
            {modal &&
                <NotificatorModal onCloseModal={() => closeModalHandler("")} opened={modal}
                                  className={styles.purchaseModal}>
                    <h1>{t("purchase.title")}</h1>
                    <h2>{t("purchase.subtitle")}</h2>
                    {isAuth ?
                        <>
                            <h3>{t("purchase.info")} <span
                                onClick={() => closeModalHandler("orders")}>{t("header.orders")}</span>
                            </h3>
                            <div className={cn(styles.btnWrapper, styles.center)}>
                                <button className="btn cancel"
                                        onClick={() => closeModalHandler("orders")}>{t("purchase.delay_payment")}</button>
                                <button className="btn"
                                        onClick={() => closeModalHandler("finances")}>{t("purchase.add_payment")}</button>
                            </div>
                        </> :
                        <h4>* {t("purchase.account")}</h4>}
                </NotificatorModal>}
        </>
    );
};

export default ConfirmData;
