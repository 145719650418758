import React, { FC, useEffect, useState } from "react";
import styles from "./ShipmentForm.module.scss";
import { Controller, useWatch } from "react-hook-form";
import Select from "../../../../../../../components/Select";
import cn from "classnames";
import { ShipmentFormProps } from "./types";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import $api from "../../../../../../../http";
import {
    TAreasResponse,
    TCitiesResponse,
    TWarehousesResponse,
} from "../../../../../../../models/NovaPoshta API/AddressResponse";
import { TSelect } from "../../../../../../../components/Select/types";

const ShipmentForm: FC<ShipmentFormProps> = ({
                                                 shipment,
                                                 isAdditional,
                                                 onSubmit,
                                                 formHandler,
                                                 isCheckout
                                             }) => {
    const { t } = useTranslation();

    const [regions, setRegions] = useState<TSelect[]>([]);
    const [cities, setCities] = useState<TSelect[]>([]);
    const [warehouses, setWarehouses] = useState<TSelect[]>([]);

    const deliveryType = useWatch({
        control: formHandler.control,
        name: "deliveryType",
        defaultValue: shipment?.deliveryType,
    });

    const enterHandler = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            formHandler.handleSubmit(onSubmit)();
        }
    };

    const validateRegion = () => {
        if (!formHandler.getValues("region")) {
            formHandler.setError("region", {
                type: "required",
                message: t("checkout.shipment.region.placeholder") || ""
            });
        }
    };

    const validateCity = (e?: React.MouseEvent<HTMLLabelElement>) => {
        if (!formHandler.getValues("city")) {
            e?.preventDefault();
            formHandler.setError("city", { type: "required", message: t("checkout.shipment.city.placeholder") || "" });
        }
    };

    const getCitiesHandler = (areaRef: string) => {
        if (areaRef) {
            formHandler.clearErrors();
            formHandler.resetField("city", { defaultValue: "" });
            formHandler.resetField("address", { defaultValue: "" });
            $api.get<{ cities: TCitiesResponse[] }>(`/api/np/getCities/${areaRef}`).then((res) => {
                setCities(res.data.cities.map(item => ({
                    label: item.title,
                    value: item.id,
                })));
                formHandler.resetField("city");
            });
        }
    };
    const getWarehousesHandler = (cityRef: string) => {
        formHandler.clearErrors();
        formHandler.resetField("address", { defaultValue: "" });
        const deliveryType = formHandler.getValues("deliveryType");
        let type;
        if (!deliveryType)
            type = "warehouse";
        else
            type = formHandler.getValues("deliveryType") === "np_warehouse" ? "warehouse" : "poshtomat";

        if (cityRef && type === "warehouse") {
            $api.get<{ warehouses: TWarehousesResponse[] }>(`/api/np/getWarehouses/${cityRef}`).then((res) => {
                setWarehouses(res.data.warehouses.map(item => ({
                    label: item.title,
                    value: item.id,
                })));
                formHandler.resetField("address");
            });
        }
    };

    useEffect(() => {
        if (shipment && shipment.region && shipment.city) {
            const fetchUserShipment = async () => {
                const regionsData = await $api.get<{ regions: TAreasResponse[] }>("/api/np/getRegions");
                const regions = regionsData.data.regions.map(item => ({ label: item.title, value: item.id }));
                setRegions(regions);
                if (shipment.region && Object.keys(shipment.region).length)
                    formHandler.setValue("region", shipment.region, { shouldTouch: true, shouldDirty: true });

                if (shipment.region?.value) {
                    const citiesData = await $api.get<{
                        cities: TCitiesResponse[]
                    }>(`/api/np/getCities/${shipment?.region?.value}`);
                    const cities = citiesData.data.cities.map(item => ({
                        label: item.title,
                        value: item.id,
                    }));
                    setCities(cities);
                    if (shipment.city && Object.keys(shipment.city).length)
                        formHandler.setValue("city", shipment.city, { shouldTouch: true, shouldDirty: true });

                    formHandler.setValue("deliveryType", shipment?.deliveryType);

                    if (shipment.address && shipment.city?.value) {
                        formHandler.setValue("address", shipment.address, { shouldTouch: true, shouldDirty: true });
                        const warehousesData = await $api.get<{
                            warehouses: TWarehousesResponse[]
                        }>(`/api/np/getWarehouses/${shipment?.city?.value}`);
                        const warehouses = warehousesData.data.warehouses.map(item => ({
                            label: item.title,
                            value: item.id,
                        }));
                        setWarehouses(warehouses);
                    }

                    formHandler.setValue("street", shipment?.street);
                    formHandler.setValue("house", shipment?.house);
                    formHandler.setValue("flat", shipment?.flat);
                }
            };

            fetchUserShipment().then();
        } else {
            $api.get<{ regions: TAreasResponse[] }>("/api/np/getRegions").then((res) => {
                setRegions(res.data.regions.map(item => ({ label: item.title, value: item.id })));
            });
            setCities([]);
            setWarehouses([]);
            formHandler.reset();
        }
    }, [formHandler, shipment]);

    return (
        <form onSubmit={formHandler.handleSubmit(onSubmit)} className={styles.shipmentForm}
              onKeyDown={enterHandler}>
            <input type="hidden" defaultValue={shipment?.id} {...formHandler.register("id")}/>
            <div className={cn(styles.block, { [styles.isCheckout]: isCheckout })}>
                <div className={cn(styles.edit, { [styles.isAdditional]: isAdditional })}>
                    {isAdditional && <div className={styles.name}>
                        <div className={styles.inputBlock}>
                            <label
                                htmlFor="lastName">{t("cabinet.personal_data.lastName.value")}<sup>*</sup></label>
                            <input type="text"
                                   placeholder={t("cabinet.personal_data.lastName.placeholder") || ""}
                                   defaultValue={shipment?.lastName} {...formHandler.register("lastName", {
                                required: t("cabinet.personal_data.lastName.placeholder") || "error",
                                maxLength: { value: 50, message: t("invalid.name.maxLength") },
                                minLength: { value: 3, message: t("invalid.name.length") }
                            })}/>
                            {formHandler.formState.errors.lastName && <span
                                className={styles.error}>{formHandler.formState.errors.lastName.message}</span>}
                        </div>
                        <div className={styles.inputBlock}>
                            <label
                                htmlFor="firstName">{t("form.name.title")}<sup>*</sup></label>
                            <input type="text" defaultValue={shipment?.firstName}
                                   className={cn({ [styles.invalid]: formHandler.formState.errors.firstName })}
                                   placeholder={t("invalid.name.empty") || ""} {...formHandler.register("firstName", {
                                required: t("invalid.name.empty") || "",
                                maxLength: { value: 50, message: t("invalid.name.maxLength") },
                                minLength: { value: 3, message: t("invalid.name.length") }
                            })}/>
                            {formHandler.formState.errors.firstName && <span
                                className={styles.error}>{formHandler.formState.errors.firstName.message}</span>}
                        </div>
                        <div className={styles.inputBlock}>
                            <label
                                htmlFor="middleName">{t("cabinet.personal_data.middle_name.value")}</label>
                            <input type="text" defaultValue={shipment?.middleName}
                                   placeholder={t("cabinet.personal_data.middle_name.placeholder") || ""} {...formHandler.register("middleName", {
                                maxLength: {
                                    value: 50,
                                    message: t("invalid.name.maxLength")
                                }, minLength: { value: 3, message: t("invalid.name.length") }
                            })}/>
                        </div>

                        <div className={styles.inputBlock}>
                            <label>{t("form.phone")}<sup>*</sup></label>
                            <Controller
                                name="phone"
                                defaultValue={shipment?.phone}
                                control={formHandler.control}
                                rules={{
                                    required: t("invalid.phone.empty") || "Error",
                                    validate: value => value.indexOf("_") === -1 ? true : t("invalid.phone.format") || "Error",
                                }}
                                render={({ field }) => (
                                    <InputMask
                                        mask="+38 (999)-999-99-99"
                                        placeholder="+38 (___)-___-__-__"
                                        id="phone"
                                        value={field.value}
                                        onChange={field.onChange}
                                        className={cn({ [styles.invalid]: formHandler.formState.errors.phone })}/>
                                )}
                            />
                            {formHandler.formState.errors.phone && <span
                                className={styles.error}>{formHandler.formState.errors.phone.message}</span>}
                        </div>
                    </div>}
                    <div className={styles.region}>
                        <label className={styles.label}>{t("cabinet.shipment_data.region")}<sup>*</sup></label>
                        <Controller name="region"
                                    control={formHandler.control}
                                    defaultValue={regions.find(w => w.value === shipment?.region?.value)}
                                    rules={{
                                        required: t("checkout.shipment.region.placeholder") || "",
                                        onChange: (e) => getCitiesHandler(e.target.value.value),
                                    }}
                                    render={({ field }) => (
                                        <Select label={t("checkout.shipment.region.placeholder")}
                                                options={regions}
                                                className={cn(styles.select, { [styles.invalid]: formHandler.formState.errors.region })}
                                                selected={field.value}
                                                listClassname={cn({ [styles.selectRegion]: regions.length > 6 })}
                                                setSelected={field.onChange}/>
                                    )}/>
                        {formHandler.formState.errors.region && <span
                            className={styles.selectError}>{formHandler.formState.errors.region.message}</span>}
                    </div>
                    <div className={styles.shipment}>
                        <label className={styles.label}>{t("checkout.shipment.title")}<sup>*</sup></label>
                        <div className={styles.radioBlock}>
                            <label htmlFor="np_warehouse" className={styles.radio} onClick={validateCity}>
                                <input type="radio" id="np_warehouse" value="np_warehouse"
                                       defaultChecked={shipment?.deliveryType === "np_warehouse"}
                                       {...formHandler.register("deliveryType", { required: "required" })}/>
                                <span>{t("checkout.shipment.method.np_warehouse")}</span>
                            </label>
                        </div>
                        <div className={styles.radioBlock}>
                            <label htmlFor="np_courier" className={styles.radio} onClick={validateCity}>
                                <input type="radio" id="np_courier" value="np_courier"
                                       defaultChecked={shipment?.deliveryType === "np_courier"}
                                       {...formHandler.register("deliveryType", { required: "required" })}/>
                                <span>{t("checkout.shipment.method.np_courier")}</span>
                            </label>
                        </div>
                    </div>
                    <div className={styles.address}>
                        {deliveryType === "np_warehouse"
                            ? <div className={styles.formBlock}>
                                <label htmlFor="address">{t("contacts.address.title")}<sup>*</sup></label>
                                <Controller name="address"
                                            control={formHandler.control}
                                            defaultValue={warehouses.find(w => w.value === shipment?.address?.value)}
                                            rules={{
                                                required: t("checkout.shipment.required") || "",
                                            }}
                                            render={({ field }) => (
                                                <Select label={t("checkout.shipment.address.placeholder")}
                                                        options={warehouses}
                                                        onClick={() => validateCity()}
                                                        openEnabled={!!formHandler.getValues("city")}
                                                        className={cn(styles.select, { [styles.invalid]: formHandler.formState.errors.address })}
                                                        selected={field.value}
                                                        listClassname={cn({ [styles.selectRegion]: warehouses.length > 6 })}
                                                        searchEnable={warehouses.length > 6}
                                                        searchPlaceholder={t("checkout.shipment.address.placeholder") || ""}
                                                        setSelected={field.onChange}/>
                                            )}/>
                                {formHandler.formState.errors.address && <span
                                    className={styles.selectError}>{formHandler.formState.errors.address.message}</span>}
                            </div>
                            : deliveryType === "np_courier" && <>
                            <div className={styles.formBlock}>
                                <label
                                    htmlFor="street">{t("checkout.shipment.street.value")}<sup>*</sup></label>
                                <input type="text" className={styles.input} id="street" defaultValue={shipment?.street}
                                       placeholder={t("checkout.shipment.street.placeholder") || ""}
                                       {...formHandler.register("street")}/>
                                {formHandler.formState.errors.street &&
                                    <span>{formHandler.formState.errors.street.message}</span>}
                            </div>
                            <div className={styles.flex}>
                                <div className={styles.formBlock}>
                                    <label htmlFor="house">{t("checkout.shipment.house.value")}<sup>*</sup></label>
                                    <input type="text" className={styles.input} id="house"
                                           defaultValue={shipment?.house}
                                           placeholder={t("checkout.shipment.house.placeholder") || ""}
                                           {...formHandler.register("house")}/>
                                    {formHandler.formState.errors.house &&
                                        <span>{formHandler.formState.errors.house.message}</span>}
                                </div>
                                <div className={styles.formBlock}>
                                    <label htmlFor="flat">{t("checkout.shipment.flat.value")}</label>
                                    <input type="text" className={styles.input} id="flat" defaultValue={shipment?.flat}
                                           placeholder={t("checkout.shipment.flat.placeholder") || ""}
                                           {...formHandler.register("flat")}/>
                                    {formHandler.formState.errors.flat &&
                                        <span>{formHandler.formState.errors.flat.message}</span>}
                                </div>
                            </div>
                        </>}
                    </div>
                    <div className={cn(styles.btnWrapper, { [styles.isCheckout]: isCheckout })}>
                        {isCheckout ? <>
                            <button className="btn cancel" onClick={() => onSubmit()}>{t("checkout.previous")}</button>
                            <button className="btn">{t("checkout.next")}</button>
                        </> : <button className="btn" type="submit">{t("cabinet.save_btn")}</button>}
                    </div>
                    <div className={styles.city}>
                        <label>{t("cabinet.shipment_data.city")}<sup>*</sup></label>
                        <Controller name="city"
                                    control={formHandler.control}
                                    rules={{
                                        required: t("checkout.shipment.city.placeholder") || "",
                                        onChange: (e) => getWarehousesHandler(e.target.value.value),
                                    }}
                                    defaultValue={cities.find(w => w.value === shipment?.city?.value)}
                                    render={({ field }) => (
                                        <Select label={t("checkout.shipment.city.placeholder")} options={cities}
                                                onClick={validateRegion}
                                                className={cn(styles.select, { [styles.invalid]: formHandler.formState.errors.city })}
                                                openEnabled={!!formHandler.getValues("region")}
                                                selected={field.value}
                                                listClassname={cn({ [styles.selectRegion]: cities.length > 6 })}
                                                searchEnable={cities.length > 6}
                                                searchPlaceholder={t("checkout.shipment.city.placeholder") || ""}
                                                setSelected={field.onChange}/>
                                    )}/>
                        {formHandler.formState.errors.city && <span
                            className={styles.selectError}>{formHandler.formState.errors.city.message}</span>}
                    </div>
                </div>

            </div>
        </form>
    );
};

export default ShipmentForm;
