import { configureStore } from "@reduxjs/toolkit";
import currencySlice from "./currency/slice";
import cartSlice from "./cart/slice";
import userSlice from "./user/slice";
import orderSlice from "./orders/slice";
import supplierSlice from "./supplier/slice";
import userAdminSlice from "./usersAdmin/slice";

const store = configureStore({
    reducer: {
        currency: currencySlice,
        cartSlice,
        userSlice,
        orderSlice,
        supplierSlice,
        userAdminSlice
    },
    devTools: process.env.NODE_ENV === "development"
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store;
