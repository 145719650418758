import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ECurrency } from "./types";
import EStatus from "../../utils/EStatus";
import Cookies from "cookies-ts";

const cookies = new Cookies();

const currencySlice = createSlice({
    name: "currency",
    initialState: {
        status: EStatus.IDLE,
        current: cookies.get("currency") ? ECurrency[cookies.get("currency") as keyof typeof ECurrency] : ECurrency.USD,
        usd: 0,
        eur: 0
    },
    reducers: {
        setCurrency(state, action: PayloadAction<{ currency: ECurrency }>) {
            state.current = action.payload.currency;
        },
    },
});

export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;
