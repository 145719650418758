import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthResponse } from "../../models/response/AuthResponse";
import { IUser, IUserLogin, IUserRegister, TUserShipmentData, TUserShipmentDataRequest } from "../../models/IUser";
import { ErrorResponse } from "../../models/response/ErrorResponse";
import $api, { API_URL } from "../../http";
import axios, { AxiosError } from "axios";
import { TNotification } from "./types";

export const loginUser = createAsyncThunk<AuthResponse & { shipment: TUserShipmentData[] }, IUserLogin, {
    rejectValue: ErrorResponse
}>("user/login", async (loginData, { rejectWithValue }) => {
    try {
        const response = await $api.post("/api/user/login", loginData);
        return response.data;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});

export const registerUser = createAsyncThunk<AuthResponse, IUserRegister & { token: string }, {
    rejectValue: ErrorResponse
}>("user/registration", async (userData, { rejectWithValue }) => {
    try {
        const { data } = await $api.post<AuthResponse>("/api/user/registration", userData);
        return data;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});

export const logoutUser = createAsyncThunk<void, void, {
    rejectValue: string
}>("user/logout", async (_, { rejectWithValue }) => {
    try {
        await $api.post("/api/user/logout");
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data.message!);
    }
});

export const checkUserAuth = createAsyncThunk<AuthResponse, void, {
    rejectValue: string
}>("user/checkAuth", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get<AuthResponse>(`${API_URL}/api/user/refresh`, { withCredentials: true });
        return response.data;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data.message!);
    }
});

export const updateUser = createAsyncThunk<AuthResponse, IUser, {
    rejectValue: string
}>("user/updateUser", async (userData, { rejectWithValue }) => {
    try {
        const response = await $api.put<AuthResponse>(`/api/user/${userData.id}`, userData);
        return response.data;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data?.message!);
    }
});

export const fetchUserShipment = createAsyncThunk<TUserShipmentData[], void, {
    rejectValue: string
}>("user/fetchUserShipment", async (_, { rejectWithValue }) => {
    try {
        const response = await $api.get<{ shipment: TUserShipmentData[] }>(`/api/user/shipment`);
        return response.data.shipment;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data?.message!);
    }
});

export const setUserShipment = createAsyncThunk<TUserShipmentData[], TUserShipmentDataRequest, {
    rejectValue: string
}>("user/setUserShipment", async (shipmentData, { rejectWithValue }) => {
    try {
        const response = await $api.post<{ shipment: TUserShipmentData[] }>(`/api/user/shipment`, { ...shipmentData });
        return response.data.shipment;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data?.message!);
    }
});

export const getNotification = createAsyncThunk<TNotification[], void, {
    rejectValue: string
}>("user/getNotification", async (_, { rejectWithValue }) => {
    try {
        const response = await $api.get<{ notifications: TNotification[] }>("/api/notification");
        return response.data.notifications;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data?.message!);
    }
});

export const removeNotification = createAsyncThunk<number, number, {
    rejectValue: string
}>("user/removeNotification", async (id, { rejectWithValue }) => {
    try {
        await $api.delete<{ success: boolean }>(`/api/notification/${id}`);
        return id;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data?.message!);
    }
});
