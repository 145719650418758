import { FC } from "react";
import styles from "./CartCheck.module.scss";
import { TCartCheck } from "./types";
import Modal from "../../Modal";
import cn from "classnames";
import { TCartItem } from "../../../store/cart/types";
import { useAppDispatch } from "../../../store/hook";
import $api from "../../../http";
import { useTranslation } from "react-i18next";
import { getCart } from "../../../store/cart/thunks";

const CartCheck: FC<TCartCheck> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const checkAnswerHandler = async (answer: boolean) => {
        if (!answer) {
            dispatch(getCart());
            localStorage.removeItem("cart");
        } else {
            const cart = JSON.parse(localStorage.getItem("cart")!) as TCartItem[];
            const cartItem = cart.map(({ id, ...item }) => item);
            await $api.post("/api/cart", { cartItem, toCalc: true });
            localStorage.removeItem("cart");
            dispatch(getCart());
        }
        onClose();
    };

    return (
        <Modal onCloseModal={() => {
        }} opened={isOpen} className={styles.cartCheck}>
            <h1>{t("cart.check.title")}</h1>
            <h2>{t("cart.check.subtitle")}</h2>
            <div className={styles.btnWrapper}>
                <button className={cn("btn", styles.delete)} onClick={() => checkAnswerHandler(false)}>
                    {t("form.delete_button")}
                </button>
                <button className="btn" onClick={() => checkAnswerHandler(true)}>
                    {t("cart.check.calc")}
                </button>
            </div>
        </Modal>
    );
};

export default CartCheck;
