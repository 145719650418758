import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: "Ua",
    debug: process.env.REACT_APP_DEV_MODE === "true",
    interpolation: {
        escapeValue: true,
    },
    detection: {
        order: ["querystring", "cookie"],
        caches: ["cookie"],
        lookupCookie: "i18next"
    },
});

export default i18n;
