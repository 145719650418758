import React, { ChangeEvent, FC } from "react";
import styles from "./FormInput.module.scss";
import cn from "classnames";
import { FormInputProps } from "./types";
import Select from "../Select";
import InputMask from "react-input-mask";

const FormInput: FC<FormInputProps> = ({
                                           label,
                                           placeholder,
                                           value,
                                           setValue,
                                           errorMessage,
                                           type,
                                           required = false,
                                           disabled = false,
                                           isReadOnly = false,
                                           options,
                                           className,
                                           onKeyUp,
                                           ...props
                                       }) => {
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputValue = e.target.value;

        if (type !== "number") {
            setValue(inputValue);
        } else if (inputValue === ".") {
            setValue("0.");
        } else if (/^-?\d*\.?\d*$/.test(inputValue)) {
            setValue(inputValue);
        }
    };

    const renderInput = () => {
        if (type === "select") {
            return (
                <Select
                    label={placeholder || ""}
                    selected={value?.toString()}
                    className={cn(styles.select, { [styles.error]: errorMessage })}
                    setSelected={(value) => setValue(value?.toString() || "")}
                    options={options}
                />
            );
        } else if (type === "phone") {
            return (
                <InputMask
                    mask="+38 (999)-999-99-99"
                    placeholder="+38 (___)-___-__-__"
                    id={label}
                    value={value}
                    onChange={onChange}
                    className={cn({ [styles.error]: errorMessage })}
                />
            );
        } else if (type === "textarea") {
            return <textarea value={value}
                             onChange={onChange}
                             placeholder={placeholder}
                             disabled={disabled}
                             className={cn({ [styles.error]: errorMessage })}
                             id={label}/>;
        } else {
            const inputType = type === "text" || type === "number" ? "text" : type;
            return (
                <input
                    type={inputType}
                    id={label}
                    readOnly={isReadOnly}
                    className={cn({ [styles.error]: errorMessage })}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onKeyUp={onKeyUp}
                    onChange={onChange}
                />
            );
        }
    };

    return (
        <div className={cn(styles.formInput, className)} {...props}>
            <label htmlFor={label}>
                {label}
                {required && <sup>*</sup>}
            </label>
            {renderInput()}
            {errorMessage && <span>{errorMessage}</span>}
        </div>
    );
};

export default FormInput;
