import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { NotificationIconProps } from "./types";
import styles from "./NotificationIcon.module.scss";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../store/hook";
import { removeNotification } from "../../../store/user/thunks";
import { TNotification } from "../../../store/user/types";

const NotificationIcon: FC<NotificationIconProps> = ({ messages }) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const markAsReadRef = useRef<HTMLSpanElement>(null);

    const checkHandler = useCallback((id: number) => {
        dispatch(removeNotification(id));
    }, [dispatch]);

    useEffect(() => {
        const handleOpened = (e: MouseEvent) => {
            const event = e as MouseEvent & { target: HTMLDivElement & { ownerSVGElement?: SVGSVGElement } };
            if (e.target !== markAsReadRef.current && event.target !== ref.current && event.target.parentElement !== ref.current && event.target.parentElement?.parentElement !== ref.current && event.target.ownerSVGElement?.parentElement?.parentElement !== ref.current)
                setIsOpen(false);
        };
        document.body.addEventListener("click", handleOpened);
        return () => {
            document.body.removeEventListener("click", handleOpened);
        };
    }, []);

    const getLink = (message: TNotification) => {
        switch (message.type) {
            case "order":
                return "/admin/";
            default:
                return `/admin/${message.type}s/${message.userId}`;
        }
    };

    return (
        <div className={styles.notification} ref={ref} onClick={() => setIsOpen(prevState => !prevState)}
             title="Уведомления">
            <div className={styles.notificationIcon}>
                <svg height="40" viewBox="0 0 512 512" width="40" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M381.7,225.9c0-97.6-52.5-130.8-101.6-138.2c0-0.5,0.1-1,0.1-1.6c0-12.3-10.9-22.1-24.2-22.1c-13.3,0-23.8,9.8-23.8,22.1   c0,0.6,0,1.1,0.1,1.6c-49.2,7.5-102,40.8-102,138.4c0,113.8-28.3,126-66.3,158h384C410.2,352,381.7,339.7,381.7,225.9z M107.2,368   c8.6-9,16.4-18.4,22.7-31.8c12-25.3,17.4-59.2,17.4-110.2c0-46.4,12.5-80.4,37.1-101.2c22.9-19.3,51.7-23.3,71.8-23.3   c20.1,0,48.9,4,71.6,23.3c24.5,20.7,37,54.5,37,100.9c0,83.8,14.9,117.3,40.3,142.3H107.2z"/>
                    <path
                        d="M256.2,448c26.8,0,48.8-19.9,51.7-43H204.5C207.3,428.1,229.4,448,256.2,448z"/>
                </svg>
                {messages.length > 0 && <span
                    style={{ fontSize: messages.length > 99 ? "8px" : "11px" }}>{messages.length > 99 ? "99+" : messages.length}</span>}
            </div>
            {isOpen && <div className={styles.notificationMenu}>
                <div className={styles.header}><h3>Уведомления</h3> <span onClick={() => checkHandler(-1)}
                                                                          ref={markAsReadRef}>Отметить все прочитанными</span>
                </div>
                {messages.map((message) => <Link
                    onClick={() => message.type === "payment" ? null : checkHandler(message.id)} to={getLink(message)}
                    className={styles.item} key={message.id}>
                    {["price", "user"].includes(message.type)
                        ? message.type === "price" ? <span className={styles.noLink}>{message.name}</span> :
                            <span dangerouslySetInnerHTML={{ __html: message.name.split("||")[0] }}
                                  className={styles.noLink}/>
                        : <>
                            <span>{`У вас ${message.type === "message" ? "новое сообщение" : message.type === "order" ? "новый заказ" : message.type === "payment" ? "новая оплата" : "новый VIN запрос"} от`}</span>
                            <span>{message.name}</span>
                        </>
                    }
                </Link>)}
            </div>}
        </div>
    );
};

export default NotificationIcon;
