import styles from "./Select.module.scss";
import { motion } from "framer-motion";
import { FC, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { SelectComponentType } from "./types";

const Select: FC<SelectComponentType> = ({ items, selected, setSelected }) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOpened = (e: MouseEvent) => {
            const event = e as MouseEvent & { path?: Node[]; target: { parentElement: HTMLDivElement } };
            if (selectRef.current && event.target?.parentElement !== selectRef.current) {
                setIsOpened(false);
            }
        };

        document.body.addEventListener("click", handleOpened);
        return () => {
            document.body.removeEventListener("click", handleOpened);
        };
    }, []);

    const handleSelectClick = () => {
        setIsOpened(!isOpened);
    };

    return (
        <div className={styles.select} ref={selectRef} onClick={handleSelectClick}>
            <div className={cn(styles.selectedHead, { [styles.open]: isOpened })}>{selected}</div>
            <motion.ul className={styles.selectList} transition={{ ease: "easeInOut", duration: 0.2 }}
                       animate={{ height: isOpened ? "auto" : 0 }}>
                {items?.map((item) => (
                    <li key={item} className={styles.selectItem} onClick={() => setSelected(item)}>
                        {item}
                    </li>
                ))}
            </motion.ul>
        </div>
    );
};

export default Select;
