import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { TOrderState } from "./types";
import { fetchOrders, setOrderRequest } from "./thunks";
import EStatus from "../../utils/EStatus";

type TBuilder = ActionReducerMapBuilder<TOrderState>
export const fetchOrderBuilder = (builder: TBuilder) => {
    builder.addCase(fetchOrders.pending, state => {
        state.status = EStatus.LOADING;
        state.orders = [];
    });
    builder.addCase(fetchOrders.fulfilled, (state, { payload }) => {
        state.status = EStatus.SUCCESS;
        state.orders = payload;
    });
    builder.addCase(fetchOrders.rejected, state => {
        state.status = EStatus.ERROR;
        state.orders = [];
    });
};

export const setOrderRequestBuilder = (builder: TBuilder) => {
    builder.addCase(setOrderRequest.pending, state => {
        state.status = EStatus.LOADING;
    });
    builder.addCase(setOrderRequest.fulfilled, (state, { payload }) => {
        state.status = EStatus.SUCCESS;
        state.orders = [payload, ...state.orders];
    });
    builder.addCase(setOrderRequest.rejected, state => {
        state.status = EStatus.ERROR;
    });
};