import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TSupplier, TSupplierEditSettingsRequest, TSupplierState } from "./types";
import EStatus from "../../utils/EStatus";
import { editSupplierBuilder, fetchSupplierBuilder } from "./builders";

const initialState: TSupplierState = {
    status: EStatus.IDLE,
    suppliers: [],
};

const supplierSlice = createSlice({
    name: "supplier",
    initialState,
    reducers: {
        setSupplierQuantity(state, { payload }: PayloadAction<{ id: number, quantity: number }>) {
            state.suppliers = state.suppliers.map(item => item.id === payload.id ? ({
                ...item,
                quantity: payload.quantity,
            }) : item);
        },
        setSupplierSettings(state, { payload }: PayloadAction<TSupplierEditSettingsRequest & { id: number }>) {
            state.suppliers = state.suppliers.map(item => item.id === payload.id ? ({
                ...item,
                ...payload,
            }) : item);
        },
        addSupplier(state, { payload }: PayloadAction<TSupplier>) {
            state.suppliers = [...state.suppliers, payload];
        },
        removeSupplier(state, { payload }: PayloadAction<number>) {
            state.suppliers = state.suppliers.filter(item => item.id !== payload);
        },
    },
    extraReducers: builder => {
        fetchSupplierBuilder(builder);
        editSupplierBuilder(builder);
    },
});

export const { setSupplierQuantity, setSupplierSettings, addSupplier, removeSupplier } = supplierSlice.actions;
export default supplierSlice.reducer;
