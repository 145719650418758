import format from "date-fns/format";
import moment from "moment";

const date = new Date();
const DateController = {
    formatDate(dateString: string | Date, dateFormat: string): string {
        if (typeof dateString !== "string")
            return format(dateString, dateFormat);
        const parts: string[] = dateString.split(".");
        if (parts.length !== 3)
            return format(new Date(dateString), dateFormat);
        const day: string = parts[0].padStart(2, "0");
        const month: string = parts[1].padStart(2, "0");
        const year: string = parts[2];
        const date = `${year}-${month}-${day}`;
        if (dateFormat === "yyyy-MM-dd")
            return date;
        return format(new Date(date), dateFormat);
    },
    getDate(date: string): Date {
        return new Date(formatDate(date, "yyyy-MM-dd"));
    },
    getFormatDate(count?: number, locale?: boolean, countDate?: number): string {
        let targetDate: Date;

        if (count) {
            targetDate = new Date(
                date.getFullYear(),
                date.getMonth() + count,
                date.getDate(),
            );
        } else if (countDate) {
            targetDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() + countDate,
            );
        } else {
            targetDate = date;
        }

        if (locale) {
            return targetDate.toLocaleDateString();
        } else {
            const year = targetDate.getFullYear();
            const month = ("0" + (targetDate.getMonth() + 1)).slice(-2);
            const day = ("0" + targetDate.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        }
    },
    equalDates(date1: Date, equalType: "=" | ">" | ">=" | "<" | "<=" | "!=", date2: Date, config: {
        resetFields: boolean
    } = { resetFields: true }): boolean {
        try {
            let d1 = date1;
            let d2 = date2;
            if (config?.resetFields) {
                d1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
                d2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
            }
            const ts1 = d1.getTime();
            const ts2 = d2.getTime();

            if (equalType === "=")
                return ts1 === ts2;
            else if (equalType === ">")
                return ts1 > ts2;
            else if (equalType === ">=")
                return ts1 >= ts2;
            else if (equalType === "<")
                return ts1 < ts2;
            else if (equalType === "<=")
                return ts1 <= ts2;
            else
                return ts1 !== ts2;
        } catch (e) {
            return false;
        }
    },
    timeUntil(targetDate: string): string {
        const now = moment();
        const end = moment(targetDate);

        const duration = moment.duration(end.diff(now));
        const days = duration.asDays();
        const hours = duration.asHours();

        if (days >= 1) {
            return `${Math.round(days)} дн`;
        } else if (hours >= 1) {
            return `${Math.round(hours)} ч`;
        } else {
            return `0 ч`;
        }
    },
    localeDateFormat(inputDate: string): string {
        const date = new Date(inputDate);
        return date.toLocaleString([], {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });
    },
    hoursUntilDate(targetDate: string) {
        const now = new Date();
        const target = new Date(targetDate);

        const timeDifference = target.getTime() - now.getTime();

        if (timeDifference <= 0) {
            return 0;
        }

        const millisecondsPerHour = 60 * 60 * 1000;
        return Math.floor(timeDifference / millisecondsPerHour);
    },
};

export const {
    formatDate,
    getFormatDate,
    localeDateFormat,
    equalDates,
    hoursUntilDate,
    getDate,
    timeUntil
} = DateController;
