import { useAppSelector } from "../../store/hook";
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import EStatus from "../../utils/EStatus";
import Loading from "../Loading";

const AuthRequire = () => {
    const { t } = useTranslation();
    const { isAuth, status } = useAppSelector((state) => state.userSlice);

    switch (status) {
        case EStatus.SUCCESS:
            if (isAuth) {
                return <Outlet/>;
            }
            break;
        case EStatus.IDLE:
            return null;
        case EStatus.LOADING:
            return <Loading/>;
        default:
            toast.error(t("toasts.auth.require"));
            return <Navigate to="/" replace state={{ openAuth: true }}/>;
    }

    return null;
};

export default AuthRequire;
