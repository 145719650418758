import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./AuthModal.module.scss";
import Modal from "../../Modal";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { TAuthModalProps } from "./types";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InputMask from "react-input-mask";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { IUserLogin, IUserRegister } from "../../../models/IUser";
import EStatus from "../../../utils/EStatus";
import Loading from "../../Loading";
import { loginUser, registerUser } from "../../../store/user/thunks";
import PasswordRequirements from "../PasswordRequirements";
import RecoveryPassword from "../RecoveryPassword";
import ReCAPTCHA from "react-google-recaptcha";

const AuthModal: FC<TAuthModalProps> = ({ open, onClose, initTab }) => {
    const dispatch = useAppDispatch();
    const { status } = useAppSelector(state => state.userSlice);
    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const captchaRef = useRef<ReCAPTCHA>(null);

    const {
        register: loginFormRegister,
        handleSubmit: loginFormHandleSubmit,
        formState: { errors: loginFormErrors },
        reset: loginFormReset,
        clearErrors: loginFormClearErrors,
    } = useForm<IUserLogin>();

    const {
        control,
        register: createFormRegister,
        handleSubmit: createFromHandleSubmit,
        formState: { errors: createFromErrors },
        reset: createFromReset,
        clearErrors: createFromClearErrors,
        setError: createFromSetError
    } = useForm<IUserRegister>();

    const onCloseModal = useCallback(() => {
        onClose();
        loginFormReset();
        loginFormClearErrors();
        createFromReset();
        createFromClearErrors();
        captchaRef.current?.reset();
        setActiveTab(0);
    }, [createFromClearErrors, createFromReset, loginFormClearErrors, loginFormReset, onClose]);

    const newPassValue = useWatch({
        control: control,
        name: "password",
        defaultValue: "",
    });

    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    const onSubmitLoginForm: SubmitHandler<IUserLogin> = data => dispatch(loginUser(data));
    const onSubmitCreateForm: SubmitHandler<IUserRegister> = data => {
        createFromClearErrors("captcha");
        const token = captchaRef.current?.getValue();
        if (!token)
            return createFromSetError("captcha", {
                type: "required",
                message: t("toasts.auth.captcha") || "Verify CAPTCHA"
            });
        captchaRef.current?.reset();
        dispatch(registerUser({ ...data, token }));
    };

    useEffect(() => {
        if (status === EStatus.SUCCESS) {
            onCloseModal();
        }
    }, [status, onCloseModal]);

    useEffect(() => {
        setActiveTab(initTab);
    }, [initTab, open]);

    return (
        <Modal onCloseModal={onCloseModal} opened={open} key={open ? "open" : "closed"}>
            {status === EStatus.LOADING && <Loading absolute modal/>}
            <Tabs className={styles.modal} selectedIndex={activeTab} onSelect={handleTabChange}>
                <TabList className={styles.modalTabList}>
                    <Tab className={styles.modalTab} selectedClassName={styles.selected}>{t("form.titles.auth")}</Tab>
                    <Tab className={styles.modalTab}
                         selectedClassName={styles.selected}>{t("form.titles.register")}</Tab>
                    <Tab></Tab>
                </TabList>

                <TabPanel className={styles.modalTabPanel} selectedClassName={styles.selected}>
                    <form onSubmit={loginFormHandleSubmit(onSubmitLoginForm)}>
                        <div className={styles.formControl}>
                            <label htmlFor="login_email">Email</label>
                            <input
                                type="email"
                                className={cn({ [styles.invalid]: loginFormErrors.email })}
                                placeholder="example@email.com"
                                id="login_email"
                                {...loginFormRegister("email", { required: t("invalid.email") || "Error" })}
                            />
                            {loginFormErrors.email && <span>{loginFormErrors.email.message}</span>}
                        </div>

                        <div className={styles.formControl}>
                            <label htmlFor="password">{t("form.password")}</label>
                            <input
                                type="password"
                                className={cn({ [styles.invalid]: loginFormErrors.password })}
                                placeholder="password123"
                                id="password"
                                {...loginFormRegister("password", { required: t("invalid.password.empty") || "Error" })}
                            />
                            {loginFormErrors.password && <span>{loginFormErrors.password.message}</span>}
                        </div>

                        <div className={styles.forgotPassword}>
                            <button type="button" onClick={() => handleTabChange(2)}>{t("form.titles.forgot")}</button>
                        </div>

                        <div className={styles.buttonWrapper}>
                            <button>{t("form.login_button")}</button>
                        </div>
                    </form>
                </TabPanel>

                <TabPanel className={styles.modalTabPanel} selectedClassName={styles.selected}>
                    <form onSubmit={createFromHandleSubmit(onSubmitCreateForm)}>
                        <div className={styles.formControl}>
                            <label htmlFor="register_lastName">{t("cabinet.personal_data.lastName.value")}</label>
                            <input
                                type="text"
                                className={cn({ [styles.invalid]: createFromErrors.lastName })}
                                placeholder="Cтепаненко"
                                id="register_lastName"
                                {...createFormRegister("lastName", {
                                    required: t("checkout.shipment.required") || "Error",
                                    minLength: { value: 3, message: t("invalid.name.length") || "Error" },
                                    maxLength: { value: 50, message: t("invalid.name.maxLength") || "Error" },
                                })}
                            />
                            {createFromErrors.lastName && <span>{createFromErrors.lastName.message}</span>}
                        </div>
                        <div className={styles.formControl}>
                            <label htmlFor="register_firstName">{t("form.name.title")}</label>
                            <input
                                type="text"
                                className={cn({ [styles.invalid]: createFromErrors.firstName })}
                                placeholder="Степан"
                                id="register_firstName"
                                {...createFormRegister("firstName", {
                                    required: t("checkout.shipment.required") || "Error",
                                    minLength: { value: 3, message: t("invalid.name.length") || "Error" },
                                    maxLength: { value: 50, message: t("invalid.name.maxLength") || "Error" },
                                })}
                            />
                            {createFromErrors.firstName && <span>{createFromErrors.firstName.message}</span>}
                        </div>
                        <div className={styles.formControl}>
                            <label htmlFor="register_middleName">{t("cabinet.personal_data.middle_name.value")}</label>
                            <input
                                type="text"
                                className={cn({ [styles.invalid]: createFromErrors.middleName })}
                                placeholder="Степанович"
                                id="register_middleName"
                                {...createFormRegister("middleName", {
                                    required: t("checkout.shipment.required") || "Error",
                                    minLength: { value: 3, message: t("invalid.name.length") || "Error" },
                                    maxLength: { value: 50, message: t("invalid.name.maxLength") || "Error" },
                                })}
                            />
                            {createFromErrors.middleName && <span>{createFromErrors.middleName.message}</span>}
                        </div>

                        <div className={styles.formControl}>
                            <label htmlFor="register_email">Email</label>
                            <input
                                type="email"
                                className={cn({ [styles.invalid]: createFromErrors.email })}
                                placeholder="example@email.com"
                                id="register_email"
                                {...createFormRegister("email", { required: t("invalid.email") || "Error" })}
                            />
                            {createFromErrors.email && <span>{createFromErrors.email.message}</span>}
                        </div>

                        <div className={styles.formControl}>
                            <label htmlFor="phone">{t("form.phone")}</label>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: t("invalid.phone.empty") || "Error",
                                    validate: value => (value.indexOf("_") === -1 ? true : t("invalid.phone.format") || "Error"),
                                }}
                                render={({ field }) => (
                                    <InputMask
                                        mask="+38 (999)-999-99-99"
                                        placeholder="+38 (___)-___-__-__"
                                        id="phone"
                                        value={field.value}
                                        onChange={field.onChange}
                                        className={cn({ [styles.invalid]: createFromErrors.phone })}
                                    />
                                )}
                            />
                            {createFromErrors.phone && <span>{createFromErrors.phone.message}</span>}
                        </div>

                        <div className={styles.formControl}>
                            <label htmlFor="password">{t("form.password")}</label>
                            <input
                                type="password"
                                className={cn({ [styles.invalid]: createFromErrors.password })}
                                placeholder="P@ssword123"
                                id="password"
                                {...createFormRegister("password", { required: t("invalid.password.empty") || "Error" })}
                            />
                            {createFromErrors.password && <span>{createFromErrors.password.message}</span>}
                        </div>
                        <PasswordRequirements value={newPassValue} className={styles.req}/>
                        <div className={cn(styles.formControl, styles.captcha)}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY || ""}
                                       hl={i18n.language === "Ru" ? "Ru" : "Uk"} theme="light"
                                       ref={captchaRef} className={styles.req}
                                       onChange={(token) => {
                                           if (token)
                                               createFromClearErrors("captcha");
                                       }}/>
                            {createFromErrors.captcha && <span>{createFromErrors.captcha.message}</span>}
                        </div>

                        <div className={styles.buttonWrapper}>
                            <button>{t("form.register_button")}</button>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel className={styles.modalTabPanel} selectedClassName={styles.selected}>
                    <RecoveryPassword cb={onCloseModal}/>
                </TabPanel>
            </Tabs>
        </Modal>
    );
};

export default AuthModal;
