import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Modal from "../../Modal";
import FormInput from "../../FormInput";
import Loading from "../../Loading";
import $api from "../../../http";
import styles from "./MarginModal.module.scss";
import { MarginModalProps, TMargin } from "./types";

const MarginModal: FC<MarginModalProps> = ({ isOpen, onClose, margins }) => {
    const {
        handleSubmit,
        setError,
        control,
        formState: { errors },
    } = useForm<TMargin>();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: TMargin) => {
        if (isNaN(Number(data.retail))) {
            setError("retail", { type: "validate", message: "Неверное значение" });
            return;
        }
        if (isNaN(Number(data.wholesale))) {
            setError("wholesale", { type: "validate", message: "Неверное значение" });
            return;
        }
        if (isNaN(Number(data.unregistered))) {
            setError("unregistered", { type: "validate", message: "Неверное значение" });
            return;
        }
        setLoading(true);
        try {
            const res = await $api.post<{ margins: TMargin }>("/api/margin", data);
            if (res.data.margins) {
                toast.success("Наценка успешно изменена");
                onClose(res.data.margins);
            } else {
                toast.error("При обновлении данных произошла ошибка");
            }
        } catch (err) {
            console.log(err);
            toast.error("При обновлении данных произошла ошибка");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal onCloseModal={onClose} opened={isOpen} className={styles.modal}>
            {loading && <Loading modal absolute/>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Наценки для клиентов</h2>
                <div className={styles.content}>
                    <Controller
                        name="wholesale"
                        defaultValue={margins?.wholesale || 0}
                        control={control}
                        rules={{ required: "Обязательное поле" }}
                        render={({ field }) => (
                            <FormInput
                                className={styles.input}
                                value={field.value}
                                setValue={field.onChange}
                                errorMessage={errors.wholesale?.message}
                                placeholder="Наценка для опта"
                                label="Опт"
                                type="number"
                            />
                        )}
                    />
                    <Controller
                        name="retail"
                        defaultValue={margins?.retail || 0}
                        control={control}
                        rules={{ required: "Обязательное поле" }}
                        render={({ field }) => (
                            <FormInput
                                className={styles.input}
                                value={field.value}
                                setValue={field.onChange}
                                errorMessage={errors.retail?.message}
                                placeholder="Наценка для розницы"
                                label="Розница"
                                type="number"
                            />
                        )}
                    />
                    <Controller
                        name="unregistered"
                        defaultValue={margins?.unregistered || 0}
                        control={control}
                        rules={{ required: "Обязательное поле" }}
                        render={({ field }) => (
                            <FormInput
                                className={styles.input}
                                value={field.value}
                                setValue={field.onChange}
                                errorMessage={errors.unregistered?.message}
                                placeholder="Наценка для клиентов без аккаунта"
                                label="Без аккаунта"
                                type="number"
                            />
                        )}
                    />
                    <div className={styles.btnWrapper}>
                        <button className="btn">Сохранить</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default MarginModal;
