import React, { FC } from "react";
import styles from "./NotificatorModal.module.scss";
import Modal from "../../../../../components/Modal";
import cn from "classnames";

type Props = {
    onCloseModal?: () => void;
    children: React.ReactNode;
    opened: boolean;
    className?: string
}

const NotificatorModal: FC<Props> = ({ onCloseModal, opened, className, children }) => {
    return (
        <Modal onCloseModal={onCloseModal} opened={opened} className={cn(styles.purchaseModal, className)}>
            {children}
        </Modal>
    );
};

export default NotificatorModal;