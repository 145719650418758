import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TNotification, TUserState } from "./types";
import EStatus from "../../utils/EStatus";
import { IUser } from "../../models/IUser";
import {
    checkUserAuthBuilder,
    fetchUserShipmentBuilder,
    getNotificationBuilder,
    loginUserBuilder,
    logoutUserBuilder,
    registerUserBuilder,
    removeNotificationBuilder,
    setUserShipmentBuilder,
    updateUserBuilder,
} from "./builders";


const initialState: TUserState = {
    status: EStatus.IDLE,
    isAuth: false,
    user: {} as IUser,
    shipment: [],
    notifications: []
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAuth(state, { payload }: PayloadAction<boolean>) {
            state.isAuth = payload;
        },
        setUser(state, { payload }: PayloadAction<IUser>) {
            state.user = payload;
        },
        setUserSliceStatus(state, { payload }: PayloadAction<EStatus>) {
            state.status = payload;
        },
        setLogout(state) {
            state.status = EStatus.ERROR;
            state.isAuth = false;
            state.user = {} as IUser;
            state.shipment = [];
        },
        addNotification(state, { payload }: PayloadAction<TNotification>) {
            state.notifications = [payload, ...state.notifications];
        }
    },
    extraReducers: builder => {
        loginUserBuilder(builder);
        registerUserBuilder(builder);
        logoutUserBuilder(builder);
        checkUserAuthBuilder(builder);
        updateUserBuilder(builder);
        fetchUserShipmentBuilder(builder);
        setUserShipmentBuilder(builder);
        getNotificationBuilder(builder);
        removeNotificationBuilder(builder);
    },
});

export const { setAuth, addNotification, setLogout, setUser, setUserSliceStatus } = userSlice.actions;

export default userSlice.reducer;
