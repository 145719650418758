import { FC, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../../components/admin/Sidebar";
import Header from "../../components/admin/Header";
import styles from "./Admin.module.scss";
import SEO from "../../components/SEO";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import EStatus from "../../utils/EStatus";
import Loading from "../../components/Loading";
import { socketAdmin } from "../../socket";
import { TNotification } from "../../store/user/types";
import { addNotification } from "../../store/user/slice";
import { getNotification } from "../../store/user/thunks";
import { fetchSuppliers } from "../../store/supplier/thunks";

const AdminLayout: FC = () => {
    const { isAuth, status, user: { isAdmin } } = useAppSelector(state => state.userSlice);
    const dispatch = useAppDispatch();
    useEffect(() => {
        socketAdmin.connect();
        dispatch(getNotification());

        const notificationHandler = (notification: TNotification) => {
            dispatch(addNotification(notification));
            if (notification.type === "price" && notification.name.match(/\((\d+)\)/))
                dispatch(fetchSuppliers());
        };

        const registerHandler = (notification: TNotification) => {
            dispatch(addNotification(notification));
        };

        socketAdmin.on("notification", notificationHandler);

        socketAdmin.on("register", registerHandler);

        return () => {
            socketAdmin.off("notification", notificationHandler);
            socketAdmin.disconnect();
        };
    }, [dispatch]);
    if (isAdmin && status === EStatus.SUCCESS)
        return (
            <div className={styles.layout}>
                <SEO title="Админ-панель"/>
                <Sidebar/>
                <Header/>
                <main className={styles.main}>
                    <Outlet/>
                    <ToastContainer position="bottom-right"/>
                </main>
            </div>
        );
    else if (status === EStatus.IDLE) {
        return <></>;
    } else if (status === EStatus.LOADING)
        return <Loading/>;
    else {
        toast.error("Ошибка доступа");
        return <Navigate to="/" replace state={{ openAuth: !isAuth }}/>;
    }
};

export default AdminLayout;
