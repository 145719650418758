import { createAsyncThunk } from "@reduxjs/toolkit";
import { TCartItem, TCartRequestItem } from "./types";
import $api from "../../http";
import { ErrorResponse } from "../../models/response/ErrorResponse";
import { AxiosError } from "axios";

export const getCart = createAsyncThunk<TCartItem[]>("cart/getCart", async () => {
    const { data } = await $api.get("/api/cart");
    return data.cart;
});

export const addCartItem = createAsyncThunk<TCartItem[], TCartRequestItem[], {
    rejectValue: string
}>("cart/addCartItem", async (cartItems, { rejectWithValue }) => {
    try {
        const { data } = await $api.post<{ cartItems: TCartItem[] }>(`/api/cart`, { cartItem: cartItems });
        return data.cartItems;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data?.message!);
    }
});