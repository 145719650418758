import React, { FC } from "react";
import styles from "./OrderItems.module.scss";
import { TOrderItemsProps } from "./types";
import Table from "../../../../../components/Table";
import cn from "classnames";
import HoverInfo from "../../../../../components/HoverInfo";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlaneSVG } from "../../../../../assets/svg/plane.svg";
import { ReactComponent as LorrySVG } from "../../../../../assets/svg/lorry.svg";
import { ReactComponent as ShipSVG } from "../../../../../assets/svg/ship.svg";
import { ECurrency } from "../../../../../store/currency/types";
import moment from "moment";
import { timeUntil } from "../../../../../utils/date";

const OrderItems: FC<TOrderItemsProps> = ({ items }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.orderTable}>
            <h2>Ваш заказ</h2>
            <Table
                headers={[t("cart.name"), t("cart.term"), t("cart.quantity"), t("cart.sum")]}
                className={styles.orderItems} fontSize={14}>
                <div className={styles.itemsBlock}>
                    {items.map(item => <div key={item.id}>
                        <div className={styles.article} title={`${item.group}, ${item.code}, ${item.description}`}>
                            <span>{item.group},</span>
                            <span className={styles.blue}>{item.code},</span>
                            <span>{item.description}</span>
                        </div>
                        <div className="flex">
                            <span
                                title={moment(item.deliveryDate).format("yyyy-MM-DD HH:mm")}>{timeUntil(item.deliveryDate)}/<span
                                className={cn({
                                        "green": item.deliveryTypeId === 3,
                                        "red": item.deliveryTypeId === 1,
                                        "blue": item.deliveryTypeId === 2,
                                    },
                                )}>{item.priceLogo}</span></span>
                            {item.deliveryTypeId === 1 &&
                                <HoverInfo text={t("delivery.plane")} position="left"><PlaneSVG/></HoverInfo>}
                            {item.deliveryTypeId === 2 &&
                                <HoverInfo text={t("delivery.ship")} position="left"><ShipSVG/></HoverInfo>}
                            {item.deliveryTypeId === 3 &&
                                <HoverInfo text={t("delivery.lorry")} position="left"><LorrySVG/></HoverInfo>}
                        </div>
                        <div>{item.states[0].quantity}</div>
                        <div>{(item.price * item.states[0].quantity).toFixed(2)} {ECurrency.USD}</div>
                    </div>)}
                </div>
                <div className={styles.total}>
                    <span>{t("cart.total")}:</span>
                    <span>{items.reduce((sum, item) => item.states[0].quantity * item.price + sum, 0).toFixed(2)} {ECurrency.USD}</span>
                </div>
            </Table>
        </div>
    );
};

export default OrderItems;
