import { FC, useCallback } from "react";
import ShipmentForm from "../../../Cabinet/components/UpdateShipmentForm/components/ShipmentForm";
import { ShipmentDataProps } from "./types";
import { TUserShipmentData } from "../../../../../models/IUser";

const ShipmentData: FC<ShipmentDataProps> = ({ form, shipment, cb }) => {
    const onSubmit = useCallback((data?: TUserShipmentData) => {
        if (!data)
            cb(0);
        else
            cb(2, data);
    }, [cb]);
    return (
        <ShipmentForm isCheckout formHandler={form} onSubmit={onSubmit} shipment={shipment}/>
    );
};

export default ShipmentData;
