import { toast } from "react-toastify";
import { t } from "i18next";

type ErrorType = string | { msg: string, param: string }

export const validateLogin = (errors?: ErrorType[]) => {
    for (let i = 0; i < errors?.length!; i++) {
        const item = errors?.[i];
        if (typeof item === "string") {
            if (item === "invalid_login")
                toast.error(t("toasts.auth.error"));
        } else {

        }
    }
};

export const validateRegister = (errors?: ErrorType[]) => {
    for (let i = 0; i < errors?.length!; i++) {
        const item = errors?.[i];
        if (typeof item === "string") {
            if (item === "email_unique")
                toast.error(t("toasts.auth.email.unique"));
            else if (item === "invalid_captcha")
                toast.error(t("toasts.error"));
        } else {
            if (item?.msg === "Неверный формат почты")
                toast.error(t("toasts.auth.email.format"));
        }
    }
};
