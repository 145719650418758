import Header from "../../components/client/Header";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styles from "./Client.module.scss";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { useEffect, useState } from "react";
import { getCart } from "../../store/cart/thunks";
import { TCartItem } from "../../store/cart/types";
import { setCart } from "../../store/cart/slice";
import CartCheck from "../../components/client/CartCheck";
import Ads from "../../components/client/Ads";
import { TAnnouncement } from "../../components/client/Ads/types";
import $api from "../../http";

const ClientLayout = () => {
    const dispatch = useAppDispatch();
    const { isAuth } = useAppSelector(state => state.userSlice);
    const [isModal, setIsModal] = useState(false);
    const [announcement, setAnnouncement] = useState<{ isOpen: boolean, info: TAnnouncement }>();
    const announcementHandler = () => {
        if (announcement) {
            localStorage.setItem("announcement", announcement.info.id.toString());
            setAnnouncement(undefined);
        }
    };
    useEffect(() => {
        if (isAuth) {
            const cartLocal = localStorage.getItem("cart");
            if (!cartLocal)
                dispatch(getCart());
            else
                setIsModal(true);
        } else {
            const cartLocal = localStorage.getItem("cart");
            if (cartLocal) {
                const cart = JSON.parse(cartLocal) as TCartItem[];
                dispatch(setCart(cart));
            }
        }
    }, [isAuth, dispatch]);

    useEffect(() => {
        // socketClient.connect()
        const timeout = setTimeout(() => {
            $api.get<{ ads: TAnnouncement }>("/api/announcement").then(res => {
                if (res.data.ads && localStorage.getItem("announcement") !== res.data.ads.id.toString())
                    setAnnouncement({ isOpen: true, info: res.data.ads });
            });
        }, 15000);
        return () => {
            clearTimeout(timeout);
            // socketClient.disconnect()
        };
    }, []);
    return (
        <>
            <Header/>
            <main className={styles.main}>
                <div className="container">
                    <Outlet/>
                    <ToastContainer autoClose={3000} position="bottom-right" newestOnTop limit={3}/>
                </div>
            </main>
            <CartCheck isOpen={isModal} onClose={() => setIsModal(false)}/>
            {announcement &&
                <Ads isOpen={announcement?.isOpen} onClose={announcementHandler} title={announcement?.info.title}
                     text={announcement?.info.text}/>}
        </>
    );
};

export default ClientLayout;
