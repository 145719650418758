import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import EStatus from "../../utils/EStatus";
import { TUsersAdminState } from "./types";
import { toast } from "react-toastify";
import { deleteUserAdmin, editUsersAdmin, fetchUsersAdmin } from "./thunks";

type TBuilder = ActionReducerMapBuilder<TUsersAdminState>
export const fetchUsersAdminBuilder = (builder: TBuilder) => {
    builder.addCase(fetchUsersAdmin.pending, state => {
        state.status = EStatus.LOADING;
        state.users = [];
    });
    builder.addCase(fetchUsersAdmin.fulfilled, (state, { payload }) => {
        state.status = EStatus.SUCCESS;
        state.users = payload;
    });
    builder.addCase(fetchUsersAdmin.rejected, (state, action) => {
        state.status = EStatus.ERROR;
        state.users = [];
        toast.error(action.payload);
    });
};

export const editUsersAdminBuilder = (builder: TBuilder) => {
    builder.addCase(editUsersAdmin.pending, state => {
        state.status = EStatus.LOADING;
        state.users = [];
    });
    builder.addCase(editUsersAdmin.fulfilled, (state, { payload }) => {
        state.status = EStatus.SUCCESS;
        state.users = state.users.map(user => user.id === payload.id ? payload : user);
        toast.success("Данные успешно обновлены");
    });
    builder.addCase(editUsersAdmin.rejected, (state, action) => {
        state.status = EStatus.ERROR;
        toast.error(action.payload?.message || "Error");
    });
};

export const deleteUserAdminBuilder = (builder: TBuilder) => {
    builder.addCase(deleteUserAdmin.pending, state => {
        state.status = EStatus.LOADING;
    });
    builder.addCase(deleteUserAdmin.fulfilled, (state, { payload }) => {
        state.status = EStatus.SUCCESS;
        if (payload.success) {
            state.users = state.users.filter(user => user.id === payload.id);
            toast.success("Пользователь удален");
        } else
            toast.error("При удалении пользователя произошла ошибка");
    });
    builder.addCase(deleteUserAdmin.rejected, (state, action) => {
        state.status = EStatus.ERROR;
        toast.error(action.payload?.message || "Error");
    });
};
