import { createSlice } from "@reduxjs/toolkit";
import { TOrderState } from "./types";
import EStatus from "../../utils/EStatus";
import { fetchOrderBuilder, setOrderRequestBuilder } from "./builders";

const initialState: TOrderState = {
    status: EStatus.IDLE,
    orders: [],
};

const orderSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {},
    extraReducers: builder => {
        fetchOrderBuilder(builder);
        setOrderRequestBuilder(builder);
    },
});

// export const {changeOrderItemStatus} = orderSlice.actions;

export default orderSlice.reducer;
